import React, { useEffect, useState } from 'react';
import styles from './TopDownloadedBooks.module.css';
import { ReactComponent as ArrowIcon } from '../../images/Shared/ArrowIcon.svg';
import { ReactComponent as ArrowIconBold } from '../../images/Reports/ArrowBold.svg';
import { ReactComponent as ArrowRightIcon } from '../../images/Reports/ArrowRightIcon.svg';
import { ReactComponent as ArrowDownIcon } from '../../images/Reports/ArrowDownIcon.svg';
import SearchBar from '../../components/Shared/SearchBar/SearchBar';
import PaginationInfo from '../../components/Shared/Pagination/PaginationInfo';
import Pagination3 from '../../components/Shared/Pagination/Pagination3';
import { CircularProgress, SelectChangeEvent } from '@mui/material';
import { showErrorToast } from '../Shared/Toastify/toastHelper';
import { PublishersService } from '../../services/PublishersService';
import { PublisherResponseDTO, PublishersDTO } from '../../dto/PublishersDTO';
import { CategoriesDTO, CategoriesResponseDTO } from '../../dto/CategoriesDTO';
import { CategoriesService } from '../../services/CategoriesService';
import { ReportsService } from '../../services/ReportsService';
import { ByBooksDTO } from '../../dto/ReportsDTO';
import { BooksDTO } from '../../dto/BooksDTO';


import ExportReportButton from '../Shared/ExportButton/ExportReportButton';

type ColumnHeader = {
  key: string;
  label: string;
  icon?: JSX.Element;
};

type ColumnHeaders = {
  [key: string]: ColumnHeader[];
};

const columnHeaders: ColumnHeaders = {
  Publishers: [
    { key: 'rank', label: 'Rank', icon: <ArrowIcon className={styles.tableArrowIcon} /> },
    { key: 'cover', label: 'Cover' },
    { key: 'title', label: 'Title', icon: <ArrowIcon className={styles.tableArrowIcon} /> },
    { key: 'grade', label: 'Grade', icon: <ArrowIcon className={styles.tableArrowIcon} /> },
    { key: 'category', label: 'Category', icon: <ArrowIcon className={styles.tableArrowIcon} /> },
    { key: 'totalDownloads', label: 'Downloads', icon: <ArrowIcon className={styles.tableArrowIcon} /> },
  ],
  Categories: [
    { key: 'rank', label: 'Rank', icon: <ArrowIcon className={styles.tableArrowIcon} /> },
    { key: 'cover', label: 'Cover' },
    { key: 'title', label: 'Title', icon: <ArrowIcon className={styles.tableArrowIcon} /> },
    { key: 'grade', label: 'Grade', icon: <ArrowIcon className={styles.tableArrowIcon} /> },
    { key: 'publisher', label: 'Publisher', icon: <ArrowIcon className={styles.tableArrowIcon} /> },
    { key: 'totalDownloads', label: 'Downloads', icon: <ArrowIcon className={styles.tableArrowIcon} /> },
  ],
  Types: [
    { key: 'rank', label: 'Rank', icon: <ArrowIcon className={styles.tableArrowIcon} /> },
    { key: 'cover', label: 'Cover' },
    { key: 'title', label: 'Title', icon: <ArrowIcon className={styles.tableArrowIcon} /> },
    { key: 'grade', label: 'Grade', icon: <ArrowIcon className={styles.tableArrowIcon} /> },
    { key: 'category', label: 'Category', icon: <ArrowIcon className={styles.tableArrowIcon} /> },
    { key: 'totalDownloads', label: 'Downloads', icon: <ArrowIcon className={styles.tableArrowIcon} /> },
  ],
};
 
const TopDownloadedBooks: React.FC = () => {
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const [selectedTab, setSelectedTab] = useState<'Publishers'|'Categories'|'Types'>('Publishers');
  const [currentPage, setCurrentPage] = useState(1);
  const [isArrowIconBold, setIsArrowIconBold] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm,setSearchTerm] = useState<string>("");
  const [inputSearchTerm,setInputSearchTerm]=useState<string>("")
  const [dropdownData,setDropdownData]=useState<(PublishersDTO|CategoriesDTO)[]>([])

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isInputDropdown,setIsInputDropdown]=useState(false)

  const [selectedId,setSelectedId]=useState<string>('')

  const [books,setBooks]=useState<BooksDTO[]>([])
  const [total,setTotal]=useState(0)
  const [pages,setPages]=useState(0)

  const [loading,setLoading]=useState(false)
  const [dropdownLoading,setDropdownLoading]=useState(true)





  const handleSearchChange = (value: string) => {
      setLoading(true)
      setSearchTerm(value); 
  };

  const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1)
  };

 
  const totalPages = Math.ceil(total / pageSize);
 

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsArrowIconBold(!isArrowIconBold);
  };

  const toggleInputDrodown=()=>{
    if(!isInputDropdown){
      setInputSearchTerm('')
    }
    setIsInputDropdown(!isInputDropdown)
  }

  const handleTabChange=(tab:string)=>{
    setSelectedTab(tab as 'Publishers'| 'Categories'| 'Types')
    setInputSearchTerm('')
    setSelectedId('')
    setBooks([]) 
    setPageSize(10)
    setCurrentPage(1)
    setTotal(0)
    setSearchTerm('')
  }
  //fetch dropdown data
  useEffect(()=>{
    const fetchData=async()=>{
      try {

        setDropdownLoading(true)

        if(selectedTab==='Publishers'){
          const response:PublisherResponseDTO = await PublishersService.getPublishers(false,1000000,1,'');
          if(Array.isArray(response.publishers)){
            setDropdownData(response.publishers)
          }
        }else if(selectedTab==='Categories'){
          const response:CategoriesResponseDTO = await CategoriesService.getCategories(1,1000000,false,'');
          if(Array.isArray(response.categories)){
            setDropdownData(response.categories)
          }
        }else{
          const response:CategoriesResponseDTO = await CategoriesService.getCategories(1,1000000,false,'');
          if(Array.isArray(response.categories)){
            setDropdownData(response.categories)
          }
        }
        

      } catch (error:any) {
        showErrorToast('error while fetching publihsers: '+error.message)
        
      }finally{
        setDropdownLoading(false)
      }
    }
    

    fetchData()
  },[selectedTab])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let response: ByBooksDTO;
        if (selectedTab === 'Categories') {
          response = await ReportsService.getTopDownloadedBooks(
            currentPage,
            pageSize,
            searchTerm,
            undefined,
            selectedId,
            undefined,
        
          );
        } else if (selectedTab === 'Publishers') {
          response = await ReportsService.getTopDownloadedBooks(
            currentPage,
            pageSize,
            searchTerm,
            selectedId,
            undefined,
            undefined,
           
          );
        } else {
          response = await ReportsService.getTopDownloadedBooks(
            currentPage,
            pageSize,
            searchTerm,
            undefined,
            undefined,
            selectedId,
         
          );
        }
  
        if (Array.isArray(response.books)) {
          setBooks(response.books);
          console.log(response.books)
          setTotal(response.total);
          setPages(response.totalPages);
        }
      } catch (error: any) {
        showErrorToast('Error while fetching table data: ' + error.message);
      } finally {
        setLoading(false);
      }
    };
  
    if (selectedId.length > 0) {
      fetchData();
    }
  }, [selectedId, currentPage, pageSize, searchTerm]);
  





  return (
    <div className={ `${styles.container} ${isDropdownOpen ? styles.containerOpen : ''}`}>
      <div className={styles.header} onClick={toggleDropdown}>
        <h2 className={styles.title}>Top Downloaded Books</h2>
        <div className={styles.icons}>
        <ExportReportButton exportType='topBooks' type={selectedTab=='Types'?selectedId:undefined} publisherId={selectedTab=='Publishers'?selectedId:undefined} categoryId={selectedTab=='Categories'?selectedId:undefined}/>

          {isArrowIconBold ? (
            <ArrowIconBold
              className={`${styles.dropdownArrow} ${isDropdownOpen ? styles.open : ''}`}
            />
          ) : (
            <ArrowRightIcon
              className={`${styles.dropdownArrow} ${isDropdownOpen ? styles.open : ''}`}
            />
          )}
        </div>
      </div>
      

      {isDropdownOpen && (
        <>
        <hr className={styles.line} />
          <div className={styles.tabs}>
            {['Publishers', 'Categories', 'Types'].map((tab) => (
              <div
                key={tab}
                className={`${styles.tab} ${selectedTab === tab ? styles.activeTab : ''}`}
                onClick={() => handleTabChange(tab)}
              >
                {tab}
              </div>
            ))}
          </div>

          <div className={styles.filterContainer}>
            <div className={styles.filterGroup}>
              <label className={styles.filterLabel}>
                {selectedTab === 'Publishers' && 'Publisher Name'}
                {selectedTab === 'Categories' && 'Category Name'}
                {selectedTab === 'Types' && 'Type Name'}
              </label>
              <div className={styles.field}>
                <div className={styles.selectWrapper} onClick={toggleInputDrodown}>
                  <div className={styles.dropdown} >
                    <input type="text" value={inputSearchTerm} onChange={(e)=>setInputSearchTerm(e.target.value)} className={styles.searchInput}/>
                    {isInputDropdown?(
                      !dropdownLoading?
                        <div className={styles.dropdownOptions}>
                          {selectedTab==='Publishers'&&
                            dropdownData
                            .filter((item): item is PublishersDTO => 'fullname' in item ) // Type guard
                            .filter((publisher) => 
                              publisher.fullname.toLowerCase().includes(inputSearchTerm.toLowerCase())
                            )
                            .map((publisher) => (
                              <div className={styles.dropdownOption} key={publisher._id} onClick={()=>{setSelectedId(publisher._id);setInputSearchTerm(publisher.fullname)}}>
                                {publisher.fullname}
                              </div>
                            ))
                          }

                          {selectedTab==='Categories'&&
                            dropdownData
                            .filter((item): item is CategoriesDTO => 'engName' in item) // Type guard
                            .filter((category) => 
                              category.engName.toLowerCase().includes(inputSearchTerm.toLowerCase())
                            )
                            .map((category) => (
                              <div className={styles.dropdownOption} key={category._id} onClick={()=>{setSelectedId(category._id);setInputSearchTerm(category.engName)}}>
                                {category.engName }
                              </div>
                            ))
                          }


                          {selectedTab==='Types'&&
                           ['School Book', 'University Book', 'Story']
                            .filter((type) => 
                              type.toLowerCase().includes(inputSearchTerm.toLowerCase())
                            )
                            .map((type,index) => (
                              <div className={styles.dropdownOption} key={index} onClick={()=>{setSelectedId(type.toLowerCase());setInputSearchTerm(type)}}>
                                {type}
                              </div>
                            ))
                          }
                        </div>:
                        <div className={styles.dropdownOptions}>

                              <div className={styles.dropdownOption}>

                        <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}><CircularProgress/></div>
                        </div>
                        </div>
                      )
                        :(
                        <></>
                      )
                    }
                  </div>
                  <ArrowDownIcon className={styles.arrowIcon} />
                  
                </div>
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <SearchBar searchTerm={searchTerm} onSearch={handleSearchChange}/>
            <PaginationInfo totalItems={total} 
            pageSize={pageSize}
            handlePageSizeChange={handlePageSizeChange}/>
          </div>

                {/* table */}
          <div className={styles.tableContainer}>
            <div className={styles.table}>
              <div className={styles.headerRow}>
                {columnHeaders[selectedTab].map(({ key, label, icon }) => (
                  <div key={key} className={styles.headerCell}  >
                      
                      {label}
                  </div>
                ))}
              </div>
              <div className={styles.tableBody}>
  {!loading ?
    books.length > 0 ?
      (books.map((book: BooksDTO) => (
        <div key={book.rank} className={styles.tableRow}>
          <div className={`${styles.cell} ${styles.rankCell}`} title={`#${book.rank}`}>
            #{book.rank}
          </div>
          <div className={styles.cell}>
            <img src={book.bookCoverUrl} alt="book cover" className={styles.imgCover} />
          </div>
          <div className={styles.cell} title={book.bookTitle}>{book.bookTitle}</div>
          <div className={styles.cell} title={
            book.grades?.map(grade => grade.engName || "No Name").join(", ") || "null"
          }>
            {book.grades && book.grades.length > 0
              ? book.grades.map(grade => grade.engName || "No Name").join(", ")
              : <i>null</i>
            }
          </div>
          <div className={styles.cell} title={
            selectedTab === 'Publishers' ? (book.category ? book.category.engName : '') :
            selectedTab === 'Categories' ? (book.publisher ? book.publisher.fullname : '') :
            book.type
          }>
            {selectedTab === 'Publishers' && book.category ? book.category.engName : ''}
            {selectedTab === 'Categories' && book.publisher ? book.publisher.fullname : ''}
            {selectedTab === 'Types' && book.type}
          </div>
          <div className={`${styles.cell} ${styles.downloadsCell}`} title={book.totalDownloads?.toString() || '0'}>
            {book.totalDownloads || '0'}
          </div>
        </div>
      )))
      :
      <p style={{ width: '100%', textAlign: 'center', padding: '16px', paddingTop: '16px' }}>
        No data found
      </p>
    :
    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px', borderBottom: '1px solid rgba(35, 35, 33, 0.2)' }}>
      <CircularProgress />
    </div>
  }
</div>
            </div>

            <div className={styles.paginationContent}>
              <Pagination3 totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TopDownloadedBooks;
