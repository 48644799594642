import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '../../images/Reports/ArrowDownIcon.svg';

import styles from './AdminAccountsForm.module.css';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import { UserService } from '../../services/UserService';
import { CreateUserDTO, UserDTO } from '../../dto/UserDTO';

import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
import { CircularProgress } from '@mui/material';

interface AdminFormProps {
  mode: 'add' | 'edit' | 'view';
}

interface AdminData {
  _id:string;
  Username: string;
  Email: string;
  Password: string;
  ConfirmPassword: string;
  Role: string; 
  maxDevices:number;
  Active:boolean;
}

const AdminAccountsForm: React.FC<AdminFormProps> = ({ mode }) => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState<AdminData>({
    _id:"",
    Username: '',
    Email: '',
    Password: '',
    ConfirmPassword: '',
    Role: 'admin',
    maxDevices:2,
    Active:false,
  });
  const [error, setError] = useState<string>('');
  const [loading,setLoading]=useState(true)
  
  const [buttonLoading,setButtonLoading]=useState(false)

  const handlePasswordBlur = () => {
    if (adminData.Password.length > 0 && adminData.Password.length < 6) {
      showErrorToast('Password must be longer than 6 characters.');
    }
  };
  const handleConfirmPasswordBlur = () => {
    if (adminData.ConfirmPassword && adminData.Password !== adminData.ConfirmPassword) {
      showErrorToast('Passwords do not match.');
    } 
  };

  //fetcch user
  useEffect(() => {
    const fetchAdmin = async () => {
      

      if(!id){
        throw new Error("id is required")
      }
      try {
        const user: UserDTO =await UserService.getUserAdmin(id)
        const formData:AdminData={
          _id:user._id,
          Username:user.fullname,
          Email: user.email,
          Password:'',
          ConfirmPassword: '',
          Role:user.role,
          maxDevices:user.maxDevices,
          Active:user.active,
        }
        setAdminData(formData);
      } catch (error:any) {
        showErrorToast('Error while fetching user: '+error.message)
      }finally{
        setLoading(false)
      }
    };

    if (mode !== 'add' && id) {
      setLoading(true)
      fetchAdmin();
    }else{
      setLoading(false)
    }
  }, [mode, id]);

  const handleSubmit =async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {

        const missingFields: string[] = [];

          // Validate the form fields
          if (!adminData.Username.trim()) missingFields.push('Username');
          if (!adminData.Email.trim()) missingFields.push('Email');
          if (!adminData.Role.trim()) missingFields.push('Role');
          if (mode === 'add' && !adminData.Password.trim()) missingFields.push('Password');
          if (mode === 'add' && !adminData.ConfirmPassword.trim()) missingFields.push('Confirm Password');
          if (missingFields.length > 0) {
            showErrorToast(`The following fields are missing: ${missingFields.join(', ')}`);
            return;
          }
          if(mode === 'add' && adminData.Password.length<6){
            showErrorToast('Password should be longer than 6 characters.');
            return
          }
          // Password confirmation check
          if (mode === 'add' && adminData.Password !== adminData.ConfirmPassword) {
            showErrorToast('Passwords do not match');
              return;
          }
   


      setError('');
      setButtonLoading(true )
      if (mode === 'add') {
        const newUser:CreateUserDTO={
          fullname:adminData.Username,
          email:adminData.Email,
          role:adminData.Role,
          password:adminData.Password,
          maxDevices:adminData.maxDevices
         }
        await UserService.createUser(newUser)
        showSuccessToast('Admin user created!')
        navigate('/adminaccounts');
      } else if (mode === 'edit') {    
        if(!id){
          showErrorToast('Id is required')
          return
        }
        if(adminData.Password.length>0 ){
          if( (adminData.Password !== adminData.ConfirmPassword) || adminData.Password.length<6){
            showErrorToast("passwords should match (minimum 6 characters)")
            return 
          }
          await UserService.updatePassword(adminData.Password,id)
          showSuccessToast('user password updated!')
        }
        const newUser={
          "email":adminData.Email,
          "fullname": adminData.Username,
          "role": adminData.Role,
        }
        await UserService.updateUser(adminData._id,newUser)
        showSuccessToast('admin user updated!')
        navigate(`/adminaccounts/view/${id}`);
      }  
    } catch (error:any) {
      showErrorToast(`Error while ${mode==='add'?"adding":"updating"} user: `+error.message)
    }finally{
      setButtonLoading(false)
    }
    
  };

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAdminData({ ...adminData, Role: e.target.value});
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<'archive' | 'delete'>('archive');
  const handleDelete = () => {
    setModalMode('delete');
    setModalOpen(true);
  };

  const handleArchive = () => {
    setModalMode('archive');
    setModalOpen(true);
  };

  const handleConfirm = async () => {
    if ( !id) {
      showErrorToast('Error user id not found.')
      return;
    }

    try {
      if (modalMode === 'delete') {
        await UserService.deleteUser( id);
        showSuccessToast('User deleted successfully');
        navigate('/adminaccounts');
      } else if (modalMode === 'archive') {
        const response=await UserService.toggleActive( id);
        showSuccessToast(response.message);
        navigate('/adminaccounts');
      }
    } catch (error:any) {
      showErrorToast(`Error: ${modalMode === 'delete' ? 'deleting' : 'archiving'} user: `+error.message);
      
    }
    setModalOpen(false);
  };

  if(loading){
    return (
      <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <CircularProgress/>
      </div>
    )
  }

  return (
    <div className={styles.background}>
      <div className={styles.Title}>Admin Account Information</div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.field}>
          <label>Username</label>
          <input
            value={adminData.Username}
            onChange={(e) => setAdminData({ ...adminData, Username: e.target.value })}
            className={`${styles.fullWidth} ${styles.textField}`}
            required
            disabled={mode==='view'}
          />
        </div>

        <div className={styles.field}>
          <label>Email Address</label>
          <input
            type="email"
            value={adminData.Email}
            onChange={(e) => setAdminData({ ...adminData, Email: e.target.value })}
            className={`${styles.fullWidth} ${styles.textField}`}
            required
            disabled={mode==='view'}

          />
        </div>

        <div className={styles.field}>
          <label>Roles</label>
          <div className={styles.selectWrapper}>
            <select
              value={adminData.Role}
              onChange={handleRoleChange}
              className={`${styles.fullWidth} ${styles.textField}`}
              required
              disabled={mode==='view'}
              style={mode==="view"?{backgroundColor:'rgb(250,250,250)'}:{}}            >

              <option value="admin">Admin</option>
              <option value="editor">Editor</option>
              <option value="sales">Sales</option>
            </select>
            <ArrowIcon className={styles.arrowIcon} />
          </div>
        </div>


       {mode!=='view' && <div className={styles.ChangePasswordContainer}>

          <div className={styles.Title}>{mode==='add'?'Create Password':'Change Password'}</div>
          <div className={styles.row}>
            <div className={styles.field}>
              <label>Password</label>
              <input
                type="password"
                value={adminData.Password}
                onChange={(e) => setAdminData({ ...adminData, Password: e.target.value })}
                className={styles.textField}
                onBlur={handlePasswordBlur}

              />
            </div>
            <div className={styles.field}>
              <label>Confirm Password</label>
              <input
                type="password"
                value={adminData.ConfirmPassword}
                onChange={(e) => setAdminData({ ...adminData, ConfirmPassword: e.target.value })}
                className={styles.textField}
                placeholder="Enter the new password again"
                onBlur={handleConfirmPasswordBlur}

              />
            </div>
            </div>
          </div>}
        {mode !== 'view' && (
          <div className={styles.buttonContainer}>
            {mode !== 'add' && (
              <>
                <button className={styles.DeleteButton} type="button" onClick={handleDelete}>DELETE</button>
                <button className={styles.ArchiveButton} type="button" onClick={handleArchive}>{adminData.Active? 'DEACTIVATE':'ACTIVATE'}</button>
              </>
            )}
            <button className={styles.AddButton} type="submit">{buttonLoading?<CircularProgress style={{color:'white'}}/>:mode === 'add' ? 'ADD' : 'UPDATE'}</button>
          </div>
        )}

      <ArchiveModal
            setIsOpen={setModalOpen}

        isOpen={modalOpen}
        onConfirm={handleConfirm}
        onCancel={() => setModalOpen(false)}
        header={`Sure you want to ${modalMode === 'archive' ? adminData.Active? 'deactivate':'activate' : 'delete'} this user?`}
        parag={`Are you sure you want to ${modalMode === 'archive' ? adminData.Active? 'deactivate':'activate' : 'delete'} this user?`}
        buttonText={modalMode === 'archive' ? adminData.Active? 'Yes, Deactivate':'Yes, Activate' : 'Delete'}
        mode={modalMode}
      />
      </form>
    </div>
  );
}

export default AdminAccountsForm;
