  import React, { useState, useEffect, useRef, useContext } from 'react';
  import { useParams, useNavigate } from 'react-router-dom';
  import FileUploadProgress from '../../components/Books/FileUploadProgress';
  import styles from './BookForm.module.css';
  import UploadImage from '../../components/Books/UploadImage';
  import DropdownSelector from '../../components/Shared/Textfields/DropdownSelector';
  import Dropdown from '../../components/Shared/Textfields/FillerDropdown';

  import Counter from '../../components/Books/Counter';
  import { PublishersService } from '../../services/PublishersService'; // Import the service
  import { GradesService } from '../../services/GradesService'; // Import the service
  import { CategoriesService } from '../../services/CategoriesService'; // Import the service
  import { CategoriesDTO } from '../../dto/CategoriesDTO';
  import { GradesDTO } from '../../dto/GradesDTO';
  import { BooksService } from '../../services/BooksService';
  import { BooksDTO, formBookData } from '../../dto/BooksDTO';
  import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
  import AuthorsInput from './AuthorsInput';
  import { CircularProgress } from '@mui/material';
  import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
  import { UserContext } from '../../UserContext';
  import Languages from '../../utils/LanguagesUtil'
import { auth } from '../../firebase/firebaseConfig';
  interface BookFormProps {
    mode: 'add' | 'edit' | 'view';
  }

  interface Option{
    label:string,
    value:string
  }
  const enpoint= process.env.REACT_APP_API_ENDPOINT
  const BookForm: React.FC<BookFormProps> = ({ mode }) => {
    const {user}=useContext(UserContext)
    const { id } = useParams<{ id?: string }>();

    const bookFileInput=useRef<HTMLInputElement|null>(null)
    const offlinebookFileInput=useRef<HTMLInputElement|null>(null)

    const navigate = useNavigate();
    const [bookData, setBookData] = useState<formBookData>({
      _id:'',
      Logo:null,
      LogoURL:'',
      Title: '',
      Synopsis: '',
      Publisher: '',
      Grade: null,
      Category: '',
      Series: '',
      ISBN: '',
      Language: '',
      Type: '',
      Program: '',
      Authors: [],
      Pages: 0,
      Edition: '',
      MainPrice: 0.0,
      SalePrice: 0.0,
      book:null,
      CopyRight:'',
      EducationMaterial:"",
      archived:false,
      published:false,
      offlineBook:null
    });
    const [fileName,setFileName]=useState('')
    const [offlineFileName,setOfflineFileName]=useState('')
    //selected data
    const [selectedGrades, setSelectedGrades] = useState<Option[]>([]);
    const [selectedPublisher, setSelectedPublisher] = useState<Option|null>(null);
    const [selectedCategory, setSelectedCategory] = useState<Option|null>(null);
    
    const [authors,setAuthors ]=useState<string[]>([]);
    
    
    //data to display in drop down
    const [publisherOptions, setPublisherOptions] = useState<Option[]>([]);
    const [gradeOptions, setGradeOptions] = useState<Option[]>([]);
    const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);
    

    //loadign
    const [loadingBooks, setLoadingBooks] = useState(true);
    const [loadingPublishers, setLoadingPublishers] = useState(true);
    const [loadingGrades, setLoadingGrades] = useState(true);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [addLoading,setAddLoading]=useState(false)
 
    const [modalOpen, setModalOpen] = useState<boolean>(false); 
    const [modalMode, setModalMode] = useState<'archive' | 'delete'|'publish'>('archive');

    

    const [isfileUpload,setIsfileUpload]=useState(false);
    
    const [uploadProgress,setUploadProgress]=useState(0)
    const [totalFileSize,setTotalFileSize]=useState(0) //online

    const [offlineTotalSize, setOfflineTotalSize] = useState<number>(0);
    const [onlineTotalSize,setOnlineFileSize]=useState(0)
    const [progressPercentage,setProgressPercentage] = useState(0);


    const [isbnError, setIsbnError] = useState<string>('');

    const [sameIsbn,setSameIsbn]=useState<string>("")

    const checkISBNExists = async (isbn: string) => {
      if (!isbn.trim()) return;
      if(sameIsbn===bookData.ISBN){
        setIsbnError("")
        return
      }
      try {
        const exists = await BooksService.checkISBN(isbn);
        if (exists) {
           setIsbnError('This ISBN is already in use.');
        } else {
          setIsbnError('');
        }
      } catch (error: any) {
        showErrorToast('Error checking ISBN: ' + error.message);
      }
    };
    
    // Trigger ISBN check when user types
    useEffect(() => {
      if (bookData.ISBN) {
        const timer = setTimeout(() => checkISBNExists(bookData.ISBN), 1000);
      
        return () => clearTimeout(timer);
      }
    }, [bookData.ISBN]);
    
    const handleISBNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
       setBookData({ ...bookData, ISBN: e.target.value });
    };

    const cancelRef=useRef<XMLHttpRequest|null>(null)

    const handleDelete = () => {
      setModalMode('delete');
      setModalOpen(true);
    };
  
    const handleArchive = () => {
      setModalMode('archive');
      setModalOpen(true);
    };

    const handlePublish = () => {
      setModalMode('publish');
      setModalOpen(true);
    };

    const handleConfirm = async () => {
      if (modalMode === 'delete' && bookData._id ) {
        try {
          const message=await BooksService.deleteBook( bookData._id);
          showSuccessToast(message||'Book deleted!');
          navigate('/books');
        } catch (error:any) {
          showErrorToast('Error deleting book: '+error.message);
        }
      } else if (modalMode === 'archive' && bookData._id) {
        try {
          const respone=await BooksService.toggleArchive( bookData._id);
          showSuccessToast(respone);
          navigate('/books');
        } catch (error:any) {
          showErrorToast('Error toggling archive book: '+error.message);
        }
      }
      else if (modalMode === 'publish' && bookData._id) {
        try {
         const response= await BooksService.togglePublish( bookData._id);
          showSuccessToast(response);
          navigate('/books');
        } catch (error:any) {
          showErrorToast('Error toggling publish book: '+error.message);
        }
      }
      setModalOpen(false); 
    };
    
    //dropdown selection change
    const handlePublisherChange = (selected: Option|Option[]) => {
      const selectedOption=selected as Option;
      setSelectedPublisher(selectedOption);
      setBookData({ ...bookData, Publisher: selectedOption.value });
    };

    const handleGradeChange = (selected: Option[]|Option) => {
      const selectedOption=selected  as Option []
      setSelectedGrades(selectedOption);
      setBookData({ ...bookData, Grade:selectedOption.map(s => s.value)});
    };
    
    const handleCategoryChange = (selected: Option[]|Option) => {
      const selectedOption=selected as Option 
      setSelectedCategory(selectedOption);
      setBookData({ ...bookData, Category: selectedOption.value });
    };

    const handlePriceChange=(value:number)=>{
      setBookData({...bookData,MainPrice:value});
    }

    const handleSalePriceChange=(value:number)=>{
      if(value>bookData.MainPrice){
        showErrorToast("Sales price should be lower the main price")
      }
      setBookData({...bookData,SalePrice:value});
    }

    const handleBookFileClick=()=>{
      if(bookFileInput.current){
        bookFileInput.current.click()
      }
    } 
    const handleOfflineBookFileClick=()=>{
      if(offlinebookFileInput.current){
        offlinebookFileInput.current.click()
      }
    } 
    const handleBookFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const selectedFile = event.target.files[0];
    
        if (selectedFile.type === "application/zip" || selectedFile.name.endsWith('.zip')) {
          const fileSizeInMB = Math.round(selectedFile.size / (1024 * 1024)); 
          setOnlineFileSize(Number(fileSizeInMB));
          setBookData({ ...bookData, book: selectedFile });
          setFileName(selectedFile.name);
        } else {
          showErrorToast('Please select a valid .zip file.');
        }
      }
    };  

    const handleOfflineBookFileChange=(event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const selectedFile = event.target.files[0];
        if (selectedFile.type === "application/zip" || selectedFile.name.endsWith('.zip')) {
          const fileSizeInMB = Math.round(selectedFile.size / (1024 * 1024)); 
          setOfflineTotalSize(Number(fileSizeInMB));
          setBookData({ ...bookData, offlineBook: selectedFile });
          setOfflineFileName(selectedFile.name);
         } else {
          showErrorToast('Please select a valid .zip file.');
        }
      }
    };  

    //fetch book
    useEffect(()=>{
      const fetchBook=async()=>{
          try {
          
          if(!id){
            showErrorToast('Error book id not found.')
            return 
          }


          const book:BooksDTO=await BooksService.getBookById(id);
          setSameIsbn(book.isbn);
          
          setBookData({
            _id:book._id,
            Logo: null,
            LogoURL:book.bookCoverUrl,
            Title: book.bookTitle || '',
            Synopsis: book.synopsis || '',
            Publisher: book.publisher?._id || '',
            Grade: book.grades.map(grade => grade._id) || "",
            Category: book.category?._id || '',
            Series: book.series || '',
            ISBN: book.isbn || '',
            Language: book.language || '',
            Type: book.type || '',
            Program: book.program || '',
            Authors: book.authors || [],
            Pages: book.pages || 0,
            Edition: book.edition || '',
            MainPrice: book.mainPrice || 0.0,
            SalePrice: book.salePrice||0.0, 
            book:null,
            CopyRight:book.copyright,
            EducationMaterial:book.educationMaterials,
            archived:book.archived,
            published:book.published,
            offlineBook:null
          });

          if (book.publisher) {
            setSelectedPublisher({ label: book.publisher.fullname, value: book.publisher._id });
          }
    
          if (book.grades.length>0) {
            const selectedGrades:Option[]=book.grades.map((grade)=>{
              return{
                value:grade._id,
                label:grade.engName
              }
            })

            setSelectedGrades(selectedGrades as Option[]);
          }
          if(book.authors){
            setAuthors(book.authors)
          }

          if (book.category) {
            setSelectedCategory({ label: book.category.engName, value: book.category._id });
          }

        } catch (error:any) {
          showErrorToast("Error fetching book: "+error.message)
        }finally {
          setLoadingBooks(false)
        }
      }
      
      if(id){
        fetchBook()
      }else{
        setLoadingBooks(false)
      }
      
    },[id,mode,gradeOptions])

    //fetch publishes
    useEffect(() => {
      const fetchPublishers = async () => {
        try {
        
          const response = await PublishersService.getPublishers(false,1000000,1,"");
          const options = response.publishers.map((publisher: any) => ({
            label: publisher.fullname,
            value: publisher._id,
          }));
          
          setPublisherOptions(options);
          
        } catch (error:any) {
          showErrorToast('Error fetching publishers: '+error.message)

        }finally{
          setLoadingPublishers(false);
        }
      };

      fetchPublishers();
    }, []);

    //fetch grades
    useEffect(() => {
      const fetchGrades = async () => {
        try {
          
          const grades = await GradesService.getGrades(1,1000000,"",false);
          const options = grades.grades.map((grade: GradesDTO) => ({
            label: grade.engName,
            value: grade._id,
          }));
          setGradeOptions(options);

            
          
        } catch (error:any) {
          showErrorToast('Error fetching grades: '+error.message)

        }finally{
          setLoadingGrades(false)
        }
      };

      fetchGrades();
    }, []);

    //fetch categories
    useEffect(() => {
      const fetchCategories = async () => {
        try {
          
          
          const categories = await CategoriesService.getCategories(1,1000000  ,false,"");   
          const options = categories.categories.map((category:CategoriesDTO) => ({
            label: category.engName,
            value: category._id,
          }));
          setCategoryOptions(options);
        
        } catch (error:any) {
          showErrorToast('Error fetching categories: '+error.message)

          console.error('Error fetching categories:', error);
        }finally{
          setLoadingCategories(false)
        }

      };

      fetchCategories();
    }, []);

    const validateForm = (): boolean => {
      const missingFields: string[] = [];
  
      if (!bookData.Title?.trim()) missingFields.push('Title');
      if (!bookData.Synopsis?.trim()) missingFields.push('Synopsis');
      if (!selectedPublisher) missingFields.push('Publisher');
      if (
          selectedGrades.length === 0 && 
          (bookData.Type?.toLowerCase() === 'school book' || bookData.Type?.toLowerCase() === 'story')
      ) {
          missingFields.push('Grades');
      }
      if (!selectedCategory && bookData.Type?.toLowerCase() === 'school book') {
          missingFields.push('Category');
      }
      if (!bookData.Series?.trim()) missingFields.push('Series');
      if (!bookData.ISBN?.trim()) missingFields.push('ISBN');
      if (!bookData.Language?.trim()) missingFields.push('Language');
      if (!bookData.Type?.trim()) missingFields.push('Type');
      if (!bookData.Program?.trim()) missingFields.push('Program');
      if (authors.length === 0) missingFields.push('Authors');
      if (!bookData.Pages) missingFields.push('Pages');
      if (!bookData.Edition?.trim()) missingFields.push('Edition');
      if (!bookData.MainPrice) missingFields.push('Main Price');
      if (!bookData.SalePrice) missingFields.push('Sale Price');
      if(bookData.SalePrice>bookData.MainPrice){
        missingFields.push('Sale Price should be lower or equal to Main Price.')
      }
      if (!bookData.EducationMaterial) missingFields.push('Education Material');
      if (!bookData.CopyRight) missingFields.push('Copyright');

      if (!bookData.offlineBook && mode==='add') missingFields.push('Offline book');
      if (!bookData.book && mode==='add') missingFields.push('Online book');
      if (!bookData.Logo && mode==='add') missingFields.push('Book Cover');
  
      if (missingFields.length > 0) {
          showErrorToast(`The following fields are missing: ${missingFields.join(', ')}`);
          return false;
      }
  
      return true;
  };
  
    const handleSubmit =async(e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if(!validateForm()){
        return 
      }
      try{
        if(bookData.ISBN!==sameIsbn &&   isbnError?.length>0){
          showErrorToast('This ISBN is already in use.')
          return
        }

        if (mode === 'add') {
          setAddLoading(true)
          await handleAddBook()
          setAddLoading(false)
          navigate('/books');
        } else if (mode === 'edit') {
          await handleEditBook()
          navigate(`/books/view/${id}`);
        }
      }catch(error){
        return
      }
    };
    
    const handleEditBook = async () => {
      try {
        const token = await auth.currentUser?.getIdToken();
        if (!token) {
          throw new Error('error getting the token');
        }
    
        const newBook = new FormData();
    
        newBook.append('bookTitle', bookData.Title);
        newBook.append('synopsis', bookData.Synopsis);
        newBook.append('publisher', bookData.Publisher);
        newBook.append('series', bookData.Series);
        newBook.append('isbn', bookData.ISBN);
        newBook.append('language', bookData.Language);
        newBook.append('type', bookData.Type.toLowerCase());

        if(bookData.Type.toLowerCase()!=='university book' && bookData.Type?.toLowerCase() !== 'story'){          
          newBook.append('category',bookData.Category);
        }
        if(bookData.Type.toLowerCase()!=='university book'){
          newBook.append('grades',JSON.stringify(bookData.Grade));
        }


        newBook.append('program', bookData.Program);
        newBook.append('authors', JSON.stringify(authors));
        newBook.append('pages', bookData.Pages.toString());
        newBook.append('edition', bookData.Edition.toString());
        newBook.append('mainPrice', bookData.MainPrice.toString());
        newBook.append('salePrice', bookData.SalePrice.toString());
 
        if (bookData.Logo) newBook.append('bookCover', bookData.Logo);
        if (bookData.book) {
  
          newBook.append('book', bookData.book);
        }
        if (bookData.offlineBook) {
          newBook.append('offlineBook', bookData.offlineBook);
        }
        newBook.append('educationMaterials', bookData.EducationMaterial || "education, test");
        newBook.append('copyright', bookData.CopyRight);
    

        const response = await new Promise((resolve, reject) => {
          const request = new XMLHttpRequest();
          cancelRef.current=request;

          request.open('PUT', enpoint+`/book/updateBook/${id}`, true);
          request.setRequestHeader('Authorization', token)
          setIsfileUpload(true)      
          // Track upload progress
          request.upload.addEventListener('progress', (event) => {
            if(event.lengthComputable){
              const uploadedMB = Math.round(event.loaded / (1024 * 1024));
              const totalMB = Math.round(event.total / (1024 * 1024));              
              setTotalFileSize(totalMB);
              setUploadProgress(uploadedMB)
              setProgressPercentage((uploadedMB / totalMB)* 100) ;
            }
          });
    
          // Handle successful upload
          request.onload = () => {
            if (request.status >= 200 && request.status < 300) {
              resolve(request.responseText); // Resolve the Promise with the response text
            } else {
              reject(new Error(`Error: ${request.status} - ${request.statusText}`));
            }
          };
    
          // Handle errors
          request.onerror = () => reject(new Error('Network error'));
    
          // Send the FormData
          request.send(newBook);      
        });
        

    
        showSuccessToast('Book Updated Successfully')
        
      } catch (error:any) {
        showErrorToast('Error updating book: '+error.message)
        throw error;
      }
    };
  
    const handleAddBook = async () => {
      try {
        const token = await auth.currentUser?.getIdToken();
        if (!token) {
          throw new Error('Error getting the token');
        }
    
        const newBook = new FormData();
        newBook.append('bookTitle', bookData.Title);
        newBook.append('synopsis', bookData.Synopsis);
        newBook.append('publisher', bookData.Publisher);
        newBook.append('type', bookData.Type.toLowerCase());
        if (bookData.Type.toLowerCase() !== 'university book' && bookData.Type?.toLowerCase() !== 'story') {
          newBook.append('category', bookData.Category);
        }
        if (bookData.Type.toLowerCase() !== 'university book') {
          newBook.append('grades', JSON.stringify(bookData.Grade));
        }
        newBook.append('series', bookData.Series);
        newBook.append('isbn', bookData.ISBN);
        newBook.append('language', bookData.Language);
        newBook.append('program', bookData.Program.toLowerCase());
        newBook.append('authors', JSON.stringify(authors));
        newBook.append('pages', bookData.Pages.toString());
        newBook.append('edition', bookData.Edition.toString());
        newBook.append('mainPrice', bookData.MainPrice.toString());
        newBook.append('salePrice', bookData.SalePrice.toString());

        if (bookData.Logo) newBook.append('bookCover', bookData.Logo);
        if (bookData.book) {
          newBook.append('book', bookData.book);
        }
        if (bookData.offlineBook) {          
          newBook.append('offlineBook', bookData.offlineBook);
        }


        newBook.append('educationMaterials', bookData.EducationMaterial);
        newBook.append('copyright', bookData.CopyRight);
    
        // Wrap XMLHttpRequest in a Promise
        const response = await new Promise((resolve, reject) => {
          const request = new XMLHttpRequest();
          cancelRef.current=request;

          request.open('POST', enpoint+'/book/createBook', true);
          request.setRequestHeader('Authorization', token)
          setIsfileUpload(true)      
          // Track upload progress
          request.upload.addEventListener('progress', (event) => {
            if(event.lengthComputable){
              const uploadedMB = Math.round(event.loaded / (1024 * 1024));
              const totalMB = Math.round(event.total / (1024 * 1024));              
              setTotalFileSize(totalMB);
              setUploadProgress(uploadedMB)
              setProgressPercentage((uploadedMB / totalMB)* 100) ;
            }
          });
    
          // Handle successful upload
          request.onload = () => {
            if (request.status >= 200 && request.status < 300) {
              resolve(request.responseText); // Resolve the Promise with the response text
            } else {
              reject(new Error(`Error: ${request.status} - ${request.statusText}`));
            }
          };
    
          // Handle errors
          request.onerror = () => reject(new Error('Network error'));
    
          // Send the FormData
          request.send(newBook);      
        });
        showSuccessToast('Book Created Successfully')
        
      } catch (error:any) {
        
        showErrorToast('Error creating book: '+error.message)
        throw error
      }finally {
        setIsfileUpload(false)
      }
    };

    const handleSelectType=(type:string)=>{
      setBookData({...bookData , Type:type})
    }

    const handleSelectBookCover=(book:File)=>{
      setBookData({...bookData , Logo:book})
    }

    const handleSelectLanguage=(language:string)=>{
      setBookData({...bookData , Language:language})
    }

    const handlePageNumberChange=(pages:number)=>{
      setBookData({...bookData , Pages:pages})
    }

 

    const Types = ['School Book', 'University Book', 'Story'];

    const handleCancel = () => {
      setBookData({...bookData,book:null})
      if (cancelRef.current) {
        
        cancelRef.current.abort(); 
        setUploadProgress(0); 
        setTotalFileSize(0);        
        setFileName('');
        setIsfileUpload(false) 
        setAddLoading(false)
      }
    };

    const handleCancelOffline= () => {
      setBookData({...bookData,offlineBook:null})
      if (cancelRef.current) {
        cancelRef.current.abort(); 
        setOfflineTotalSize(0);        
        setOfflineFileName('');
        setAddLoading(false)
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    if(loadingBooks||loadingCategories||loadingGrades||loadingPublishers){
      return (
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100%'}}>
          <CircularProgress/>
        </div>
      )
    }

    return (
      <div className={styles.background}>
        <div className={styles.Title}>Book Information</div>
        <form onSubmit={handleSubmit} className={styles.form} onKeyDown={handleKeyDown}>
          <div className={styles.uploadContainer}>
            <div className={styles.uploadImage}>
              <UploadImage setBookData={handleSelectBookCover} imageUrl={bookData.LogoURL} mode={mode}/>
            </div>  
            <div className={styles.fieldsContainer}>
              <div>
                <label>Book Title</label>
                <textarea
                  value={bookData.Title}
                  onChange={(e) => setBookData({ ...bookData, Title: e.target.value })}
                  className={styles.BookTitleField}
                  disabled={mode==="view"}
                />
              </div>
              <div>
                <label>Synopsis</label>
                <textarea
                  value={bookData.Synopsis}
                  onChange={(e) => setBookData({ ...bookData, Synopsis: e.target.value })}
                  className={styles.desctextField}
                  disabled={mode==="view"}
                />
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.field}>
              <DropdownSelector 
                label="Publisher"
                options={publisherOptions} 
                selectedOptions={selectedPublisher} 
                onChange={handlePublisherChange} 
                multiSelect={false} 
                disabled={mode==="view"}
                />
            </div>
             {bookData.Type?.toLowerCase() !== 'university book'? <div className={styles.field}>
              <DropdownSelector
                label="Grade"
                options={gradeOptions} 
                selectedOptions={selectedGrades} 
                onChange={handleGradeChange} 
                multiSelect={true} 
                disabled={mode==="view"}
              />
              </div> :<></>}
           </div>

          <div className={styles.row}>
              {bookData.Type?.toLowerCase() !== 'university book' && bookData.Type?.toLowerCase() !== 'story'?
              <div className={styles.field}>
                <DropdownSelector
                  label="Category"
                  options={categoryOptions} 
                  selectedOptions={selectedCategory} 
                  onChange={handleCategoryChange} 
                  multiSelect={false}
                  disabled={mode==="view"}
                />
              </div>:<></>}
            <div className={styles.field}>
              <label>Series</label>
              <input
                type="text"
                value={bookData.Series}
                onChange={(e) => setBookData({ ...bookData, Series: e.target.value })}
                className={styles.textField}
                disabled={mode==="view"}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.field}>
              <label>ISBN</label>
              <input
                type="text"
                value={bookData.ISBN}
                onChange={(e) => 
                  handleISBNChange(e)
                  }
                className={styles.textField}
                disabled={mode==="view"}
              />
              {isbnError && <p className="error text-red-500">{isbnError}</p>}

            </div>
            <div className={styles.field}>
              <Dropdown label="Language" options={Languages} selectedOption={bookData.Language} onSelect={handleSelectLanguage} disabled={mode==="view"}/>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.field}>
              <Dropdown label="Type" options={Types} selectedOption={bookData.Type} onSelect={handleSelectType} disabled={mode==="view"}/>
            </div>
            <div className={styles.field}>
              <label>Program</label>
              <input type="text" value={bookData.Program} onChange={(e)=>setBookData({...bookData,Program:e.target.value})}                 
               className={styles.textField}
                disabled={mode==="view"} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.field}>
            <label>Educational Material</label>
            <input type="text" value={bookData.EducationMaterial} onChange={(e)=>setBookData({...bookData,EducationMaterial:e.target.value})}                  className={styles.textField}
                disabled={mode==="view"} />
            </div>
            <div className={styles.field}>
              <label>Copyright</label>
              <input type="text" value={bookData.CopyRight} onChange={(e)=>setBookData({...bookData,CopyRight:e.target.value})}                  
              className={styles.textField}
                disabled={mode==="view"} />
            </div>
          </div>
          <div className={styles.field}>
            <label className={styles.label}>Authors</label>
            <AuthorsInput authorsList={authors} setAuthors={setAuthors} disabled={mode==='view'}/>
          </div>

          <div className={styles.row}>

            <div className={styles.field}>
              <label className={styles.label}>Pages</label>
              <Counter initialCount={bookData.Pages} count={bookData.Pages} setCount={handlePageNumberChange} disabled={mode==='view'} />   
            </div>

            <div className={styles.field}>
              <label>Edition</label>
              <input
                disabled={mode==='view'}
                type="text"
                value={bookData.Edition}
                onChange={(e) => setBookData({ ...bookData, Edition: e.target.value })}
                className={styles.textField}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.field}>
              <label className={styles.label}>Main Price ($)</label>
              <Counter initialCount={bookData.MainPrice} count={bookData.MainPrice}  setCount={handlePriceChange}  disabled={mode==='view'}/> 
            </div>
            <div className={styles.field}>
              <label className={styles.label}>Sale Price ($)</label>
              <Counter initialCount={bookData.SalePrice} count={bookData.SalePrice} setCount={handleSalePriceChange} disabled={mode==='view'} /> 
            </div>
          </div>

            {mode!=="view"?
              <>
               <div className={styles.TitleUpload}>Book Upload</div>
            
              <div className={styles.row}>
              <div className={styles.field}>
                <label>Online Zip File Upload</label>

                {bookData.book ? (
                  <div className={styles.upladingContainer}>
                    <FileUploadProgress
                      fileName={fileName}
                      uploadedSize={uploadProgress}
                      totalSize={onlineTotalSize}
                      onCancel={handleCancel}
                    />
                    <div>
                    <input
                        type="file"
                        accept=".zip"
                        style={{ display: 'none' }}
                        ref={bookFileInput}
                        onChange={handleBookFileChange}
                      />
                      <button
                        onClick={handleBookFileClick}
                        className={styles.fileButton}
                        type="button"
                      >
                      change file
                      </button>
                      </div>
                    </div>
                ) : (
                  <div className={styles.bookInputContainer}>
                    <input
                      type="file"
                      accept=".zip"
                      style={{ display: 'none' }}
                      ref={bookFileInput}
                      onChange={handleBookFileChange}
                    />
                    <button
                      onClick={handleBookFileClick}
                      className={styles.fileButton}
                      type="button"
                    >
                    Choose file
                    </button>
                  </div>
                )}
              </div>
                <div className={styles.field}>
                  <label>Offline Zip File Upload</label>

                  {bookData.offlineBook ? (
                    <div className={styles.upladingContainer}>
                        <FileUploadProgress
                          fileName={offlineFileName}
                          uploadedSize={uploadProgress}
                          totalSize={offlineTotalSize}
                          onCancel={handleCancelOffline}
                        />
                      <div>
                        <input
                          type="file"
                          accept=".zip"
                          style={{ display: 'none' }}
                          ref={offlinebookFileInput}
                          onChange={handleOfflineBookFileChange}
                        />
                        <button
                          onClick={handleOfflineBookFileClick}
                          className={styles.fileButton}
                          type="button"
                        >
                          change file
                        </button>
                      </div>
                      </div>
                  ) : (
                    <div className={styles.bookInputContainer}>
                      <input
                        type="file"
                        accept=".zip"
                        style={{ display: 'none' }}
                        ref={offlinebookFileInput}
                        onChange={handleOfflineBookFileChange}
                      />
                      <button
                        onClick={handleOfflineBookFileClick}
                        className={styles.fileButton}
                        type="button"
                      >
                        Choose file
                      </button>
                    </div>
                  )}
                </div>
            
              </div>
              </>
            :null}
             
            
          {mode !== 'view' && (
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
              <div>
               {isfileUpload?
                <div className={styles.progressContainer}> 
                    <div className={styles.progressBar}>
                      <div className={styles.progress} style={{ width: `${progressPercentage}%` }}></div>
                  </div>
                  <div className={styles.fileSize}>
                      {uploadProgress}MB/{totalFileSize} MB
                    </div>
                </div>:
                mode==="edit" && <i style={{color:'red'}}>Choosing a new file will override the old one</i>}
                </div>
              <div className={styles.buttonContainer}>
                {mode==='edit'&& user?.role==='admin' ?
                  <>
                  <button className={styles.DeleteButton} type="button" onClick={handleDelete}>DELETE</button>
                  <button className={styles.ArchiveButton} type="button" onClick={handlePublish}>{bookData.published?'UNPUBLISH':'PUBLISH'}</button>
                  <button className={styles.ArchiveButton} type="button" onClick={handleArchive}>{bookData.archived?'UNARCHIVE':'ARCHIVE'}</button>
                  </>
                  :<></>
                }
                <button className={styles.AddButton} type="submit" >{!addLoading? mode === 'add' ? 'ADD' : 'UPDATE':<CircularProgress style={{color:'white'}}/>}</button>
              </div>
            </div>
          )}
        </form>
        <ArchiveModal
            setIsOpen={setModalOpen}

        isOpen={modalOpen}
        onConfirm={handleConfirm}
        onCancel={() => setModalOpen(false)}
        header={modalMode === 'archive' ?`${bookData?.archived===true?'Unarchive':'Archive'} Book` : modalMode==='publish'? `${bookData?.published===true?'UnPublish':'Publish'} Book`:  'Delete Book'}
        parag={modalMode === 'archive' ? `Are you sure you want to ${bookData?.archived===true?'unarchive':'archive'} this book?` : modalMode==='publish'? `Are you sure you want to ${bookData?.published===true?'unpublish':'publish'} this book?`: 'Are you sure you want to delete this book?'}
        buttonText={modalMode === 'archive' ? bookData?.archived===true?'Yes, Unarchive':'Yes, Archive' : modalMode==='publish'? bookData?.published===true?'unpublish':'publish' : 'Delete'}
        mode={modalMode}
      />
      </div>
    );
  }
    
  export default BookForm;
